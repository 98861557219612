// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #4ba892;
$blue: #3f95a9;
$gray: #ececec;
$brown: #574332;
$brown-dark: #00110e;
$brown-light: #886652;
$white: #fff;
$black: #000;
// theme-colors
$primary: $blue;
$secondary: $brown;

// body
$body-bg: $gray;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Dosis:wght@200;300;400;500;600;700;800&family=Patrick+Hand&display=swap");
// old font
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
$font-family-primary-old: "Raleway", sans-serif;
$font-family-primary: $font-family-primary-old;
$font-family-secondary: "Architects Daughter", cursive;
$font-family-tertiary: "Dosis", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1.125rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px 48px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-tertiary;
$headings-font-weight: 400;
$headings-color: $black;

// contextual
$light: $green;
$dark: $brown;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $green;
$matrix-color-secondary: $primary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
