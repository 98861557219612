// header
.header {
	z-index: 998;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		.container-fluid {
			position: fixed;
			background: $white;
			top: 0;
			.logo {
				max-width: 120px;
			}
			.site-switcher,
			.header-contact {
				transform: translateX(10rem);
				opacity: 0;
				transition: 0.05s ease-out;
			}
		}
		.container-fluid.notification-bar {
			margin-top: -6vh;
			transition: 0.5s ease-out;
		}
	}

	.container,
	.container-fluid {
		z-index: 900;
		.container-holder {
			> .column {
				align-items: center;
				padding-left: 2.5rem !important;
				padding-right: 2.5rem !important;
				@include media-breakpoint-down(xl) {
					flex-flow: row wrap;
					justify-content: flex-end;
					padding-left: 15px !important;
					padding-right: 15px !important;
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.notification-bar {
			background: $brown-light;
			padding: 10px 15px;
			transform: none;
			transition: 0.5s ease-in;
			margin-top: 0;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: -5vh;
			left: 0;
			height: 6vh;
			width: 100%;
			background: url("/images/rand-fffff-1.svg");
			background-repeat: repeat-x;
			z-index: -1;
		}
		@include media-breakpoint-down(lg) {
			background: $white;
		}
	}

	// notification bar header
	div.header-notifications {
		width: 100%;

		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100%;
			li,
			li a {
				color: $white;
				font-family: $font-family-secondary;
				font-size: 16px;

				&:hover {
					opacity: 0.9;
					&::before {
						transform: rotate(360deg);
						transition: 0.7s ease;
					}
				}
			}
			li {
				&::before,
				&::after {
					font-family: $font-awesome;
					font-weight: 600;
					display: inline-block;
				}
				&::before {
					content: "\f005";
					margin-right: 2rem;
				}
				&:last-child {
					&::after {
						content: "\f005";
						margin-left: 2rem;
					}
				}
				&.active {
					font-weight: 700;
				}
			}
			@include media-breakpoint-down(lg) {
				flex-flow: row nowrap !important;
				padding: 0.45rem;
				li {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					opacity: 0;
					min-width: 100% !important;
					flex-basis: 100% !important;
					max-width: 100% !important;
					text-align: center;
					transition: 1.35s ease;
					font-weight: 700;
					pointer-events: none;
					&::after {
						content: "\f005";
						margin-left: 2rem;
					}
					&.active {
						opacity: 1;
						transition: 1.35s ease;
						pointer-events: all;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 1000;
		.navbar-toggler {
			background: $green;
			aspect-ratio: 1/1;
			min-width: 45px;
			width: 45px;
			i {
				color: $white;
				transition: 0.35s ease;
				transition-delay: 0.5s;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);
					transition: 0.35s ease;
					transition-delay: 0.5s;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 184px;
		min-width: 184px;
		margin: 10px 10px 10px 0;
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(xl) {
			order: unset;
			margin-left: 1.5rem;
			margin-right: auto;
		}
		@include media-breakpoint-down(sm) {
			order: 3;
			margin: 0.75rem auto;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;
			position: fixed;
			height: 100%;
			bottom: 0;
			top: 0;
			left: -100vw;
			right: 0;
			width: 100vw;
			background: $green;
			z-index: 990;
			transition: 0.35s;
			display: flex;
			justify-content: center;
			align-items: center;
			&.show {
				left: 0;
				bottom: 0;
				right: 0;
				top: 0;
				transition: 0.35s;
			}
			.nav-item {
				text-align: center;
				.nav-link {
					color: $white;
					text-transform: uppercase;
					font-family: $font-family-tertiary;
					font-weight: 700;
				}
				.dropdown-menu.show {
					position: static !important;
					transform: none !important;
					border: 0 !important;
					margin: 0 !important;
					background: none !important;
					.nav-link {
						font-weight: 400;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 1.5rem;
				.nav-item {
					margin: 0 0.5rem;

					.nav-link {
						color: $brown;
						text-transform: uppercase;
						font-weight: 700;
						font-family: $font-family-tertiary;
						font-size: 16px;
						display: flex;
						align-items: center;

						span {
							display: flex;
							float: left;
							min-width: 5px;
						}
					}
					&:hover,
					&.active {
						.nav-link {
							color: $blue;
							span {
							}
						}
					}
					.dropdown-menu.show {
						min-width: 14rem !important;
						width: fit-content;
						&::after,
						&::before {
							display: none;
						}
						border: none;
						box-shadow: none;
						.nav-item {
							.nav-link {
								display: flex;
								flex-flow: row nowrap;
								color: $black !important;
								&:hover {
									color: $green !important;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-btn
	div.book-button {
		order: 5;
		a {
			@extend .btn, .btn-primary;
			background: $green;
			border: none;
			&:hover {
				background: rgba($green, 0.75);
			}
		}
		@include media-breakpoint-down(lg) {
			order: 2;
		}
	}
	// contact buttons
	div.header-contact {
		position: absolute;
		right: 1rem;
		top: 15px;
		transition: 0.25s ease-out;
		width: 4rem;
		ul {
			margin-left: auto;
			display: flex;
			gap: 0.65rem;
			justify-content: flex-end;
			li {
				i {
					font-size: 16px;
					transition: 0.5s ease;
					color: $brown;
				}
				.list-item-title {
					display: none;
				}
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.5s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	// site-switcher
	.site-switcher {
		position: absolute;
		right: calc(3rem + 3.5rem);
		top: 15px;
		transition: 0.25s ease-out;
		li {
			a {
				box-shadow: none !important;
				border: none !important;
				padding: 0 !important;
				font-family: $font-family-tertiary !important;
				font-size: 16px !important;
				color: $brown !important;
				&::before {
					content: "/";
					float: right;
				}
			}
			&:last-child {
				a {
					&::before {
						display: none;
					}
				}
			}
			&:hover {
				a {
					box-shadow: none !important;
					border: none !important;
					img {
						transform: scaleX(-1);
						transition: 0.5s ease;
					}
				}
			}
			&.active,
			&:hover {
				a {
					color: $blue !important;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			position: relative;
			right: unset;
			top: unset;
			margin-left: auto;

			ul {
				flex-flow: row;
				margin-right: 0.25rem;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		&.sticky-mobile {
			.container-fluid:not(.notification-bar) {
				position: fixed;
				top: 0;
			}
			.logo {
				transition: 0.35s;
				display: none;
				opacity: 0;
			}
		}
	}
}
