// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card,
.card {
	border: none;
	box-shadow: 4px 4px 8px #00000029;
	border-radius: 10px;
	.card-image {
		padding: 0.75rem;
		border-radius: 10px;
		.card-img,
		img {
			border-radius: 10px;
		}
		.card-image-caption {
			bottom: 70%;
			left: -70%;
			transform: rotate(-45deg);
			.card-image-label {
				display: flex;
				justify-content: center;
				font-weight: 700;
			}
		}
	}
	.card-body {
		padding: 1.35rem !important;
		.card-title {
			font-size: 24px;
			font-family: $font-family-secondary;
			font-weight: 200;
			text-transform: uppercase;
		}
		.card-subtitle {
			font-family: $font-family-tertiary;
			font-weight: 200;
		}
		.card-description {
			font-size: 18px;
			font-family: $font-family-primary;
		}
	}
}

.gallery-link {
	border-radius: 10px;
}
