// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none;
	}
	.owl-nav {
		button {
			margin: 1rem;
			i {
				font-size: 24px;
				&::before {
					content: "\f35a";
				}
			}
			&.owl-prev {
				i {
					transform: scaleX(-1);
				}
			}
		}
	}
}
// .eyecatcher .item{
// filter: brightness(0.5);
// }
section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: -3px;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/white-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: bottom;

		z-index: 990;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	min-height: 70vh;
	.owl-dots {
		bottom: 6vh;
	}
	.owl-title {
		display: block !important;
		font-size: 48px !important;
		font-family: $font-family-secondary !important;
		font-weight: 400;
		@include media-breakpoint-down(md) {
			font-size: 32px !important;
		}
	}
}

section.home-info-section {
	.owl-carousel .owl-item {
		height: 75vh;
		.owl-caption-holder {
			height: 100%;
			display: flex;
			align-items: flex-end;
			z-index: 200;
			.owl-title {
				display: block !important;
				font-size: 48px !important;
				font-family: $font-family-secondary !important;
				font-weight: 400;
			}
		}
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: linear-gradient(
				to top,
				rgba(#000, 0.15),
				rgba(#fff, 0) 50%
			);
			z-index: 100;
		}
	}
}
