// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home intro section

.home-intro-section {
	position: relative;
	margin: 6vh 0;
	&:hover {
		&::after {
			transform: scale(1.05) translateY(-20%);
			transition: 1s ease;
		}
	}
	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
			.title {
				font-size: 48px;
				text-transform: uppercase;
				font-family: $font-family-tertiary;
				font-weight: 400;
				max-width: 600px;
				text-transform: center;
				margin: auto;
			}
		}
	}
	&::before {
		content: "";
		background: url("/images/whitepatch.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		width: 20vw;
		height: 25vh;
		top: -6vh;
		left: 10vw;
		position: absolute;
		display: block;
		z-index: 990;
		transform: translateY(-30%);
		pointer-events: none;
	}
	&::after {
		content: "";
		background: url("/images/deblauwelantaarn.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		width: 23vw;
		height: 40vh;
		top: -6vh;
		left: 10vw;
		position: absolute;
		z-index: 990;
		transform: translateY(-30%);
		pointer-events: none;
		display: inline-block;
		transition: 1s ease;
	}
	.icon-mobile {
		display: none;
	}
	@include media-breakpoint-down(md) {
		.icon-mobile {
			display: block;
			margin-top: 6vh;
		}
		&:after,
		&::before {
			display: none;
		}
	}
}

// home-info-section

.home-info-section {
	background: $green;
	margin: 12vh 0 6vh 0;
	position: relative;
	.bottom {
		position: relative;
		margin-top: 6vh;
		.column {
			padding: 0 !important;
		}
		&::before {
			content: "";
			position: absolute;
			top: -2px;
			left: 0;
			height: 6vh;
			width: 100%;
			background: url("/images/rand-4BA892-2.svg");
			background-position: bottom;
			background-repeat: repeat-x;
			background-size: contain;
			z-index: 900;
		}
		@include media-breakpoint-down(md) {
			&::before {
				background-position: top !important;
				top: -1px;
			}
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: -6vh;
		left: 0;
		height: 6vh;
		width: 100%;
		background: url("/images/rand-4BA892-3.svg");
		background-position: bottom;
		background-repeat: repeat-x;
		// background-size: 100%;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		height: 6vh;
		width: 100%;
		background: url("/images/rand-ECECEC-4.svg");
		background-position: bottom;
		background-repeat: repeat-x;
		z-index: 1;
	}
	@include media-breakpoint-down(md) {
		padding-top: 6vh;
		&::before {
			top: -5.95vh;
		}
	}
	.card {
		box-shadow: 4px 4px 8px #00000029;
		.card-title,
		.card-subtitle {
			text-align: center;
		}
		.card-title {
			font-weight: 400;
		}
		&:hover {
			background: rgba($white, 0.9);
		}
	}
}

// usp section
.usp-section {
	z-index: 900;
	.container-one-column.intro {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;

			.title {
				font-size: 36px;
				text-transform: uppercase;
				font-family: $font-family-tertiary;
				font-weight: 400;
				max-width: 600px;
				text-transform: center;
				margin: auto;
			}
		}
	}
	.usp-container {
		margin-top: 6vh;
		margin-bottom: -12vh;
		ul.usp-list-home {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: center;

			li {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				background: $white;
				aspect-ratio: 1/1;
				border-radius: 50%;
				box-shadow: $shadow;
				max-width: 210px;
				width: 210px;
				transition: 0.35s ease;
				z-index: 900;
				i,
				span {
					color: $blue;
					text-align: center;
				}
				i {
					font-size: 60px;
					display: inline-block;
					transition: 0.35s ease;
				}
				span {
					font-size: 18px;
					text-transform: uppercase;
					font-weight: 700;
					font-family: $font-family-tertiary;
					max-width: 85%;
				}
				&:hover {
					transform: translateY(-5px) scale(1.05);
					transition: 0.35s ease;
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
				@include media-breakpoint-down(md) {
					max-width: 40%;
					width: 40%;
					padding: 1rem;
					i {
						font-size: 44px;
					}
					span {
						font-size: 12px;
						max-width: 90%;
						overflow: hidden;
					}
				}
			}
		}
	}
}

// facilities section
.facilities-section {
	background: $brown;
	padding: 18vh 0 18vh 0;
	position: relative;
	z-index: 800;
	&::before {
		content: "";
		position: absolute;
		top: -1px;
		left: 0;
		height: 6vh;
		width: 100%;
		background: url("/images/rand-ECECEC-2.svg");
		background-position: bottom;
		background-repeat: repeat-x;
		z-index: -1;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -3px;
		left: 0;
		height: 5vh;
		width: 100%;
		background: url("/images/rand-ECECEC-2.svg");
		background-position: top;
		background-repeat: repeat-x;
		z-index: -1;
		transform: scaleY(-1);
		border: none;
	}
	@include media-breakpoint-down(md) {
		&::before {
			background-position: top;
		}
		&::after {
			bottom: -1px;
		}
	}
}

// home outro section
.home-outro-section {
	margin: 6vh auto;
	.title {
		font-size: 30px;
		text-transform: uppercase;
		font-weight: 400;
		font-family: $font-family-tertiary;
	}
	.description {
		font-size: 20px;
		font-family: $font-family-primary;
	}
	div.button-link {
		max-width: fit-content;
		margin-right: 0.5rem;
		display: inline-block;
		a {
			@extend .btn, .btn-primary;
			@include media-breakpoint-down(md) {
				margin-bottom: 0.5rem;
			}
		}
	}
	.container {
		.one {
			display: block;
		}
	}
	@include media-breakpoint-down(md) {
		.two {
			margin-top: 2rem;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	.title,
	.title > * {
		font-weight: 400 !important;
		font-family: $font-family-tertiary !important;
		text-transform: uppercase;
	}
	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				padding: 0.5rem 0.75rem;
				background: $green;
				color: $white;
				border-radius: 6px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
