// footer
&.home {
	.footer {
		background: none;
	}
}
.footer {
	background: $white;
	.footer-logo {
		position: relative;
		padding: 6vh 0;
		.logo {
			display: flex;
			justify-content: center;
			img {
				width: 284px;
			}
		}
		@include media-breakpoint-down(md) {
			padding: 1rem;
		}
	}
	div.partner-logos {
		position: absolute;
		right: 0;
		height: 100%;
		display: flex;
		align-items: flex-end;
		ul {
			display: flex;
			align-items: center;
			gap: 0.85rem;
			li {
				display: flex;
				align-items: flex-end;
				img {
					min-height: 22px;
					max-height: 50px;
					opacity: 0.85;
					transition: 0.35s ease;
				}
				.list-item-title {
					display: none;
				}
				&:hover {
					img {
						opacity: 1;
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			position: relative;
			ul {
				justify-content: center;
				gap: 0.5rem;
				width: 100%;
				margin-top: 12vh;
			}
		}
	}
	.footer-contact {
		background: $blue;
		margin-top: 6vh;
		padding: 6vh 0;
		position: relative;
		.container {
			.container-holder {
				text-align: center;
				margin: auto;
			}
		}
		.title,
		.title > * {
			color: $white;
			font-family: $font-family-secondary;
			font-size: 36px;
			font-weight: 400;
		}
		.details,
		.details a {
			color: $white;
			font-family: $font-family-primary;
		}
		.btn {
			background: $white;
			color: $blue !important;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: -6vh;
			height: 6vh;
			width: 100%;
			background: url("/images/rand-3F95A9-4.svg");
			background-size: 110%;
			background-position: top;
		}
		ul.social-media {
			display: flex;
			justify-content: center;
			gap: 0.25rem;
			margin-top: 4vh;
			li a {
				display: flex;
				justify-content: center;
				align-items: center;
				background: $white;
				aspect-ratio: 1/1;
				width: 36px;
				border-radius: 50%;
				text-decoration: none;
				i {
					color: $blue;
				}

				.list-item-title {
					display: none;
				}
				&:hover {
					text-decoration: none;
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-top: 1rem;
			&::before {
				height: 1rem;
				top: -0.95rem;
			}
		}
	}
	.footer-socket {
		background: $blue;
		.footer-link {
			.list {
				display: flex;
				justify-content: center;
				gap: 1rem;
				padding: 0.5rem 15px;
				li {
					a {
						color: $white;
						text-decoration: none;
						font-family: $font-family-primary;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
